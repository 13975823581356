import Vue from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {BootstrapVue} from 'bootstrap-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import Vue2Filters from 'vue2-filters'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import '@/assets/util.css';
import wb from "./registerServiceWorker";

import {
    faUserSecret,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faCamera,
    faTasks,
    faSave,
    faFlagUsa,
    faClipboardCheck,
    faPencilAlt,
    faFilePdf,
    faPaperclip,
    faFile,
    faDownload,
    faAsterisk,
    faSignInAlt,
    faUser,
    faSignOutAlt,
    faInbox,
    faBoxes,
    faStopwatch,
    faSpinner,
    faDollyFlatbed,
    faFileDownload,
} from '@fortawesome/free-solid-svg-icons';


library.add(faUserSecret, faChevronRight, faChevronLeft, faChevronUp,
    faChevronDown, faCamera, faTasks, faSave, faFlagUsa, faClipboardCheck,
    faPencilAlt, faFilePdf, faPaperclip, faFile, faDownload, faAsterisk,
    faSignInAlt, faUser, faSignOutAlt, faInbox, faBoxes, faStopwatch,
    faSpinner, faDollyFlatbed, faFileDownload);

Vue.use(BootstrapVue);
Vue.use(Vue2Filters);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;

Vue.prototype.$toastSuccess = {
    title: `Success`,
    variant: 'success',
    autoHideDelay: 1000,
    solid: true
};
Vue.prototype.$toastError = {
    title: `Error`,
    variant: 'danger',
    // autoHideDelay: 1000,
    solid: true
};

if (window.location.hostname !== 'localhost' && window.location.hostname !== '192.168.1.69') {
    Vue.prototype.$SERVER = window.location.host;
    axios.defaults.baseURL = location.protocol + '//' + Vue.prototype.$SERVER + '/api/customer';
} else {
    Vue.prototype.$SERVER = '192.168.1.69:9091';
    axios.defaults.baseURL = location.protocol + '//' + Vue.prototype.$SERVER + '/api/customer';
}

axios.interceptors.request.use((config) => {
    config.headers['X-Token'] = (JSON.parse(localStorage.getItem('xdata')) ?
        JSON.parse(localStorage.getItem('xdata')).token : '');
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const vm = new Vue();
    let status = error.response.status;
    switch (status) {
        case 401:
            localStorage.removeItem('xdata');
            location.href = "/";
            break;
        case 500:
            break;
    }
    if (status !== 404)
        vm.$bvToast.toast(`ERROR ${status}: ${error.response.data.msg}`, Vue.prototype.$toastError);
    return Promise.reject(error);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
