<template>
    <div class="home">
        <b-modal id="pdfViewer" size="xl" ok-only>
            <div slot="modal-title">
                <font-awesome-icon icon="file-pdf"/>
                PDF Viewer
            </div>
            <object class="pdfViewer" :data="filename+'#view=FitW'">
                <p class="text-center">
                    Download PDF file to view
                    <a class="pdf btn btn-success btn-block" target="_blank" :href="filename" download>
                        <font-awesome-icon icon="file-download"></font-awesome-icon>
                        Click to Download
                    </a>
                </p>
            </object>
        </b-modal>

        <div class="">
            <router-link tag="button" class="btn btn-primary btn-block mt-5" to="/receiving">
                <font-awesome-icon icon="inbox"/>
                View Receivings
            </router-link>
            <router-link tag="button" class="btn btn-primary btn-block mt-4" to="/moves">
                <font-awesome-icon icon="dolly-flatbed"/>
                View Moves
            </router-link>
            <button class="btn btn-primary btn-block mt-4" @click.prevent="getStockStatus">
                <font-awesome-icon icon="boxes"/>
                Stock Status Report
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Home',
        components: {},
        created() {
            axios.get(`/ping`)
        },
        data() {
            return {
                filename: "",
            }
        },
        methods: {
            getStockStatus() {
                this.$emit('spinner', true);
                axios.get(`/stock?format=pdf`)
                    .then((result) => {
                        this.filename = `${location.protocol}//${this.$SERVER}/${result.data.path}`;
                        this.$bvModal.show('pdfViewer');
                        this.$emit('spinner', false);
                    })
                    .catch(() => this.$emit('spinner', false));
            },
        }
    }
</script>

<style>
    .pdfViewer {
        width: 100% !important;
        height: 65vh;
    }
</style>
