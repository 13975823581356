<template>
    <div>
        <b-modal id="pdfViewer" size="xl" ok-only>
            <div slot="modal-title">
                <font-awesome-icon icon="file-pdf"/>
                PDF Viewer
            </div>
            <object class="pdfViewer" :data="filename+'#view=FitW'">
                <object class="pdfViewer" :data="filename+'#view=FitW'">
                    <p class="text-center">
                        Download PDF file to view
                        <a class="pdf btn btn-success btn-block" target="_blank" :href="filename"
                           download>
                            <font-awesome-icon icon="file-download"></font-awesome-icon>
                            Click to Download
                        </a>
                    </p>
                </object>
            </object>
        </b-modal>

        <b-modal id="fileList" size="lg" ok-only>
            <div slot="modal-title">
                <font-awesome-icon icon="paperclip"/>
                Files Attached
            </div>
            <ul class="list-group">
                <a :href="file.url" target="_blank" class="list-group-item" v-for="(file, index) in files"
                   v-bind:key="index">{{file.filename}}
                    <font-awesome-icon class="float-right" icon="download"/>
                </a>
                <li class="list-group-item" v-if="files.length===0">No Files</li>
            </ul>
        </b-modal>

        <div class="border p-2 mb-2">
            <h5 class="text-center">REF#{{receiving.reference}} PO#{{receiving.purchase_order}}</h5>
            <hr>
            <div class="d-flex justify-content-between">
                <button class="btn btn-secondary" @click="getSummary">
                    <font-awesome-icon icon="file-pdf"/>
                    Summary
                </button>
                <button class="btn btn-secondary ml-2" @click="getQCReport">
                    <font-awesome-icon icon="file-pdf"/>
                    QC Report
                </button>
                <button class="btn btn-secondary ml-2" @click="$bvModal.show('fileList')">
                    <font-awesome-icon icon="paperclip"/>
                    Files Attached
                </button>
            </div>
        </div>

        <b-list-group>
            <b-list-group-item class="bg-light font-weight-bold ">
                <div class="row">
                    <div class="col-3">SKU</div>
                    <div class="col-4">DESC</div>
                    <div class="col-3">LOT</div>
                    <div class="col-2">QTY</div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center p-2"
                               v-for="(item, index) in items" v-bind:key="index">
                <div class="container-fluid">
                    <div class="d-block d-sm-block d-md-none">
                        <div class="row">
                            <div class="col-4"><span class="badge badge-secondary">{{item.sku | uppercase}}</span></div>
                            <div class="col-4">{{item.lot}}</div>
                            <div class="col-4 text-truncate">{{item.qty}} {{item.unit | uppercase}}</div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12">{{item.description}}</div>
                        </div>
                    </div>
                    <div class="d-none d-md-block">
                        <div class="row">
                            <div class="col-3"><span class="badge badge-secondary">{{item.sku | uppercase}}</span></div>
                            <div class="col-4">{{item.description}}</div>
                            <div class="col-3">{{item.lot}}</div>
                            <div class="col-2">{{item.qty}} {{item.unit | uppercase}}</div>
                        </div>
                    </div>

                </div>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingDetail",
        components: {},
        mounted() {
            this.$emit('spinner', true);
            axios.get(`/receiving/${this.$route.params['id']}?key=num`)
                .then((result) => {
                    this.receiving = result.data;
                    axios.get(`/receiving/${this.$route.params['id']}/items`)
                        .then((result) => this.items = result.data)
                        .catch(() => this.$emit('spinner', false));
                    axios.get(`/receiving/${this.$route.params['id']}/files`)
                        .then((result) => this.files = result.data)
                        .catch(() => this.$emit('spinner', false));
                    this.$emit('spinner', false);
                })
                .catch(() => this.$emit('spinner', false));
        },
        data() {
            return {
                filename: "",
                files: [],
                receiving: {
                    id: null,
                    idcustomer: null,
                    customer: "",
                    user: null,
                    time: null,
                    reference: "",
                    purchase_order: "",
                    notes: "",
                    status: "done",
                    created: "2000-01-01 12:00:00",
                },
                items: [],
            }
        },
        computed: {},
        methods: {
            getSummary() {
                this.$emit('spinner', true);
                axios.get(`/receiving/${this.receiving.id}/summary`)
                    .then((result) => {
                        this.filename = `${location.protocol}//${this.$SERVER}/${result.data.path}`;
                        this.$bvModal.show('pdfViewer');
                        this.$emit('spinner', false);
                    })
                    .catch(() => this.$emit('spinner', false));
            },
            getQCReport() {
                this.$emit('spinner', true);
                axios.get(`/receiving/${this.receiving.id}/qcreport`)
                    .then((result) => {
                        this.filename = `${location.protocol}//${this.$SERVER}/${result.data.path}`;
                        this.$bvModal.show('pdfViewer');
                        this.$emit('spinner', false);
                    })
                    .catch(() => this.$emit('spinner', false));
            },
        }
    }
</script>

<style scoped>
    .pdfViewer {
        width: 100% !important;
        height: 65vh;
    }
</style>