import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Moves from '../views/moves/List'
import Receivings from '../views/receivings/List'
import ReceivingDetail from '../views/receivings/Detail'
import Login from '../views/Login'

Vue.use(VueRouter);

const routes = [{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
        title: 'Home',
        ico: 'home',
    }
}, {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
        title: 'Home',
        ico: 'home',
    }
}, {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
        title: 'Home',
        ico: 'home',
    }
}, {
    path: '/moves',
    name: 'Moves',
    component: Moves,
    meta: {
        title: 'Moves',
        ico: 'inventory',
    }
}, {
    path: '/receiving',
    name: 'ReceivingList',
    component: Receivings,
    meta: {
        title: 'Receivings',
        ico: 'inbox',
    }
}, {
    path: '/receiving/:id',
    name: 'ReceivingDetail',
    component: ReceivingDetail,
    meta: {
        title: 'Receiving Detail',
        ico: 'detail',
    }
}, {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
