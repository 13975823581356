<template>
    <div>
        <form @submit.prevent="onSearch" class="mb-2">
            <input type="text" name="search" class="search" placeholder="" autocomplete="off" v-model="search">
            <button type="submit" class="btn btn-outline-secondary mt-2" v-if="false">Search</button>
        </form>
        <b-list-group>
            <b-list-group-item class="bg-light font-weight-bold ">
                <div class="row">
                    <div class="col-3">REF#</div>
                    <div class="col-2 d-none d-md-block">STATUS</div>
                    <div class="col-3">PO#</div>
                    <div class="col-3">ARRIVED</div>
                    <div class="col-1"></div>
                </div>
            </b-list-group-item>
            <b-list-group-item :to="`/receiving/${item.id}`" v-for="(item, index) in rows" v-bind:key="index">
                <div class="d-block d-sm-block d-md-none">
                    <div class="row">
                        <div class="col-6">{{item.reference}}</div>
                        <div class="col-6">{{item.purchase_order}}</div>
                        <div class="col-10">{{item.done}}</div>
                        <div class="col-1">
                            <font-awesome-icon icon="chevron-right"/>
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block">
                    <div class="row">
                        <div class="col-3">{{item.reference}}</div>
                        <div class="col-2">
                            <span class="badge" :class="classColor(item)">{{item.status | uppercase}}</span>
                        </div>
                        <div class="col-3">{{item.purchase_order}}</div>
                        <div class="col-3">{{item.done}}</div>
                        <div class="col-1">
                            <font-awesome-icon icon="chevron-right"/>
                        </div>
                    </div>
                </div>
            </b-list-group-item>
            <li class="list-group-item text-center" v-if="rows.length===0">No Content</li>
        </b-list-group>
        <div class="fixed-bottom d-flex">
            <div class="mx-auto" v-if="totalPages>1">
                <b-pagination-nav :link-gen="linkGen" @change="pageChange" :number-of-pages="totalPages" use-router/>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ReceivingList",
        mounted() {
            this.pageChange()
        },
        data() {
            return {
                search: '',
                limit: (window.innerWidth <= 425 ? 4 : 10),
                rows: [],
                total: 0,
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.total / this.limit)
            },
        },
        methods: {
            onSearch() {
                this.pageChange();
            },
            classColor(item) {
                switch (item.status) {
                    case 'pending':
                        return 'badge-secondary';
                    case 'ontransit':
                        return 'badge-primary';
                    case 'done':
                        return 'badge-success';
                    case 'cancel':
                        return 'badge-danger';
                    default:
                        return 'badge-secondary';
                }
            },
            pageChange(page = 1) {
                this.$emit('spinner', true);
                axios.get(`/receiving?status=done&search=${this.search}&offset=${page === 1 ? 0 : (page * this.limit) - this.limit}&limit=${this.limit}`)
                    .then((result) => {
                        this.rows = result.data.rows;
                        this.total = result.data.total;
                        this.$emit('spinner', false);
                    })
                    .catch(() => this.$emit('spinner', false));
            },
            linkGen(pageNum) {
                return (pageNum === 1 ? '?' : `?page=${pageNum}`);
            }
        }
    }
</script>

<style scoped>
    input.search {
        width: 130px;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        background-color: white;
        background-image: url('~@/assets/searchicon.png');
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 12px 20px 12px 40px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
    }

    input.search:focus {
        width: 100%;
    }
</style>